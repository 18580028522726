import React from "react";

const Contact = () => {
    const handleGoToMail = () => {
        // Replace 'mailto:' with the appropriate mailto link, including the recipient email address, subject, and body.
        const mailtoLink = `mailto:${'bugprasad@gmail.com'}?subject=${'Interested in Collaborating!'}&body=${'Your message body'}`;

        // Open the user's default email client with the mailto link.
        window.location.href = mailtoLink;
    };
    return (
        <div id="contact-section" className="contact-section-container">
            <div className="contact-section">
                <div className="contact-details">
                    <p>Phone Number / WhatsApp</p>
                    <p>+91 98403 86369</p>
                    <br />
                    <p>Mail id</p>
                    <p>bugprasad@gmail.com</p>

                    <div className="icons-section">
                        {/* Facebook */}
                        <a href="https://www.facebook.com/ganeshprasad.basavanahalli?mibextid=ZbWKwL">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
                                <path d="M41.25 22.501C41.25 12.151 32.85 3.75098 22.5 3.75098C12.15 3.75098 3.75 12.151 3.75 22.501C3.75 31.576 10.2 39.1322 18.75 40.876V28.126H15V22.501H18.75V17.8135C18.75 14.1947 21.6938 11.251 25.3125 11.251H30V16.876H26.25C25.2188 16.876 24.375 17.7197 24.375 18.751V22.501H30V28.126H24.375V41.1572C33.8438 40.2197 41.25 32.2322 41.25 22.501Z" fill="black" />
                            </svg>
                        </a>
                        {/* Mail */}
                        <svg onClick={handleGoToMail} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="48"><path d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-302L140-685v465h680v-465L480-462Zm0-60 336-218H145l335 218ZM140-685v-55 520-465Z" /></svg>
                        {/* Linkedin */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
                            <path d="M35.625 5.625C36.6196 5.625 37.5734 6.02009 38.2766 6.72335C38.9799 7.42661 39.375 8.38044 39.375 9.375V35.625C39.375 36.6196 38.9799 37.5734 38.2766 38.2766C37.5734 38.9799 36.6196 39.375 35.625 39.375H9.375C8.38044 39.375 7.42661 38.9799 6.72335 38.2766C6.02009 37.5734 5.625 36.6196 5.625 35.625V9.375C5.625 8.38044 6.02009 7.42661 6.72335 6.72335C7.42661 6.02009 8.38044 5.625 9.375 5.625H35.625ZM34.6875 34.6875V24.75C34.6875 23.1289 34.0435 21.5741 32.8972 20.4278C31.7509 19.2815 30.1961 18.6375 28.575 18.6375C26.9812 18.6375 25.125 19.6125 24.225 21.075V18.9938H18.9938V34.6875H24.225V25.4437C24.225 24 25.3875 22.8188 26.8313 22.8188C27.5274 22.8188 28.1951 23.0953 28.6874 23.5876C29.1797 24.0799 29.4562 24.7476 29.4562 25.4437V34.6875H34.6875ZM12.9 16.05C13.7354 16.05 14.5366 15.7181 15.1274 15.1274C15.7181 14.5366 16.05 13.7354 16.05 12.9C16.05 11.1562 14.6438 9.73125 12.9 9.73125C12.0596 9.73125 11.2536 10.0651 10.6594 10.6594C10.0651 11.2536 9.73125 12.0596 9.73125 12.9C9.73125 14.6438 11.1562 16.05 12.9 16.05ZM15.5063 34.6875V18.9938H10.3125V34.6875H15.5063Z" fill="black" />
                        </svg> */}
                        {/* Instagram */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
                            <path d="M14.625 3.75098H30.375C36.375 3.75098 41.25 8.62598 41.25 14.626V30.376C41.25 33.2602 40.1043 36.0263 38.0648 38.0658C36.0253 40.1052 33.2592 41.251 30.375 41.251H14.625C8.625 41.251 3.75 36.376 3.75 30.376V14.626C3.75 11.7417 4.89576 8.97565 6.93521 6.93619C8.97467 4.89673 11.7408 3.75098 14.625 3.75098ZM14.25 7.50098C12.4598 7.50098 10.7429 8.21214 9.47703 9.47801C8.21116 10.7439 7.5 12.4608 7.5 14.251V30.751C7.5 34.4822 10.5188 37.501 14.25 37.501H30.75C32.5402 37.501 34.2571 36.7898 35.523 35.524C36.7888 34.2581 37.5 32.5412 37.5 30.751V14.251C37.5 10.5197 34.4813 7.50098 30.75 7.50098H14.25ZM32.3438 10.3135C32.9654 10.3135 33.5615 10.5604 34.001 10.9999C34.4406 11.4395 34.6875 12.0356 34.6875 12.6572C34.6875 13.2788 34.4406 13.875 34.001 14.3145C33.5615 14.754 32.9654 15.001 32.3438 15.001C31.7222 15.001 31.126 14.754 30.6865 14.3145C30.2469 13.875 30 13.2788 30 12.6572C30 12.0356 30.2469 11.4395 30.6865 10.9999C31.126 10.5604 31.7222 10.3135 32.3438 10.3135ZM22.5 13.126C24.9864 13.126 27.371 14.1137 29.1291 15.8719C30.8873 17.63 31.875 20.0146 31.875 22.501C31.875 24.9874 30.8873 27.372 29.1291 29.1301C27.371 30.8883 24.9864 31.876 22.5 31.876C20.0136 31.876 17.629 30.8883 15.8709 29.1301C14.1127 27.372 13.125 24.9874 13.125 22.501C13.125 20.0146 14.1127 17.63 15.8709 15.8719C17.629 14.1137 20.0136 13.126 22.5 13.126ZM22.5 16.876C21.0082 16.876 19.5774 17.4686 18.5225 18.5235C17.4676 19.5784 16.875 21.0091 16.875 22.501C16.875 23.9928 17.4676 25.4236 18.5225 26.4785C19.5774 27.5333 21.0082 28.126 22.5 28.126C23.9918 28.126 25.4226 27.5333 26.4775 26.4785C27.5324 25.4236 28.125 23.9928 28.125 22.501C28.125 21.0091 27.5324 19.5784 26.4775 18.5235C25.4226 17.4686 23.9918 16.876 22.5 16.876Z" fill="black" />
                        </svg> */}
                        {/* Youtube */}
                        <a href="https://www.youtube.com/@BUGANESHPRASAD-1973">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
                                <path d="M18.75 28.126L28.4813 22.501L18.75 16.876V28.126ZM40.425 13.4447C40.6688 14.326 40.8375 15.5072 40.95 17.0072C41.0813 18.5072 41.1375 19.801 41.1375 20.926L41.25 22.501C41.25 26.6072 40.95 29.626 40.425 31.5572C39.9563 33.2447 38.8688 34.3322 37.1813 34.801C36.3 35.0447 34.6875 35.2135 32.2125 35.326C29.775 35.4572 27.5438 35.5135 25.4813 35.5135L22.5 35.626C14.6438 35.626 9.75 35.326 7.81875 34.801C6.13125 34.3322 5.04375 33.2447 4.575 31.5572C4.33125 30.676 4.1625 29.4947 4.05 27.9947C3.91875 26.4947 3.8625 25.201 3.8625 24.076L3.75 22.501C3.75 18.3947 4.05 15.376 4.575 13.4447C5.04375 11.7572 6.13125 10.6697 7.81875 10.201C8.7 9.95723 10.3125 9.78848 12.7875 9.67598C15.225 9.54473 17.4563 9.48848 19.5188 9.48848L22.5 9.37598C30.3563 9.37598 35.25 9.67598 37.1813 10.201C38.8688 10.6697 39.9563 11.7572 40.425 13.4447Z" fill="black" />
                            </svg>
                        </a>
                        {/* Twitter */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                            <path d="M42.1127 11.249C40.6689 11.9053 39.1127 12.3365 37.5002 12.5428C39.1502 11.549 40.4252 9.97402 41.0252 8.08027C39.4689 9.01777 37.7439 9.67402 35.9252 10.049C34.4439 8.43652 32.3627 7.49902 30.0002 7.49902C25.5939 7.49902 21.9939 11.099 21.9939 15.5428C21.9939 16.1803 22.0689 16.799 22.2002 17.3803C15.5252 17.0428 9.58144 13.8365 5.62519 8.98027C4.93144 10.1615 4.5377 11.549 4.5377 13.0115C4.5377 15.8053 5.94394 18.2803 8.11894 19.6865C6.78769 19.6865 5.55019 19.3115 4.4627 18.749V18.8053C4.4627 22.7053 7.23769 25.9678 10.9127 26.699C9.73281 27.0219 8.49412 27.0668 7.29394 26.8303C7.80321 28.4287 8.80058 29.8273 10.1458 30.8295C11.4911 31.8318 13.1167 32.3872 14.7939 32.4178C11.9507 34.6686 8.42644 35.8853 4.80019 35.8678C4.16269 35.8678 3.5252 35.8303 2.8877 35.7553C6.45019 38.0428 10.6877 39.374 15.2252 39.374C30.0002 39.374 38.1189 27.1115 38.1189 16.4803C38.1189 16.124 38.1189 15.7865 38.1002 15.4303C39.6752 14.3053 41.0252 12.8803 42.1127 11.249Z" fill="black" />
                        </svg> */}
                    </div>
                </div>
                <div className="mail-section">
                    <p>Interested in Collaborating ?</p>
                    <p>Contact me!</p>
                    <span>Feel free to contact me !</span>
                    <button className="go-to-mail-button" onClick={handleGoToMail}>
                        Go to Mail
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Contact;